import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './styles/index.scss'
import router from './router'
import store from './store'
import './directive'
import App from './App.vue'
import './filters'
import moment from 'moment'
import theConfirm from '@/lib/Confirm/index.js' // 全局confirm
//引入相关样式
import '@vue-office/docx/lib/index.css'
import '@vue-office/excel/lib/index.css'

Vue.config.productionTip = false
Vue.prototype.$theConfirm = theConfirm
Vue.prototype.$moment = moment
Vue.use(ElementUI)
Vue.prototype.openLoading = function(text) {
  const loading = this.$loading({ // 声明一个loading对象
    lock: true, // 是否锁屏
    text: text || '加载中', // 加载动画的文字
    spinner: 'el-icon-loading', // 引入的loading图标
    background: 'rgba(0, 0, 0, 0.8)', // 背景颜色
    fullscreen: true,
    customClass: 'loadingclass' // **遮罩层新增类名，如果需要修改loading的样式**
  })
  return loading
}
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
