<template>
  <div class="about-dialog-container">
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="visible"
      width="814px"
      custom-class="profile-dialog"
    >
      <div
        slot="default"
        class="center"
      >
        <img src="@/assets/layout/天堰科技logo2.png">
        <span v-show="$store.getters.userInfo.role!=='4'">虚拟仿真智慧学习平台2.0</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'AboutDialog',
  data() {
    return {
      visible: false
    }
  },
  created() {

  },
  methods: {
    openDialog() {
      this.visible = true
    }
  }
}
</script>

<style scoped lang="scss">
.about-dialog-container{

  /deep/ .el-dialog__body{
    padding: 0;
  }
  .center{
    background: url("@/assets/home/关于弹窗背景.png") 0 0 no-repeat;
    background-size: contain;
    height: 571px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 130px;
    img{
      width: 342px;
      margin-bottom: 35px;
    }
    span{
      font-size: 36px;
      color: #000000;
      font-weight: 500;
    }
  }


}
</style>
