
<template>
  <el-dialog
    class="login-dialog"
    :visible.sync="dialogVisible"
    width="600px"
    :destroy-on-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="closeLoginPage"
  >
    <div slot="title">
      <img
        style="height: 45px;margin-left: 10px"
        src="@/assets/layout/天堰科技logo2.png"
      >
    </div>
    <div
      class="login-form"
      @keyup.enter="login()"
    >
      <div class="title">{{ isSoftWork ? '登录' : '虚拟仿真智慧学习平台2.0' }}</div>
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleTagSelect"
      >
        <el-menu-item index="1">密码登录</el-menu-item>
        <el-menu-item index="2">验证码登录</el-menu-item>
      </el-menu>
      <!--      <div class="form-item">-->
      <!--        <div class="label">组织机构：</div>-->
      <!--        <el-select-->
      <!--          v-model="value"-->
      <!--          placeholder="请选择"-->
      <!--        >-->
      <!--          <el-option-->
      <!--            v-for="item in options"-->
      <!--            :key="item.value"-->
      <!--            :label="item.label"-->
      <!--            :value="item.value"-->
      <!--          />-->
      <!--        </el-select>-->
      <!--      </div>-->
      <!--密码登录-->
      <template v-if="activeIndex==='1'">
        <div class="form-item">
          <div class="label">用户名：</div>
          <el-input
            v-model="username"
            maxlength="200"
            placeholder="请输入用户名"
          />
        </div>
        <div class="form-item">
          <div class="label">密码：</div>
          <el-input
            v-model="password"
            maxlength="200"
            :show-password="true"
            placeholder="请输入密码"
          />
        </div>
      </template>
      <!--验证码登录-->
      <template v-else>
        <div class="form-item">
          <el-form
            ref="yzmLoginForm"
            :rules="yzmRules"
            :model="yzmForm"
          >
            <el-form-item prop="phone">
              <div class="label">手机号：</div>
              <el-input
                v-model="yzmForm.phone"
                maxlength="11"
                placeholder="请输入手机号"
              />
            </el-form-item>
            <el-form-item prop="code">
              <div
                style="margin-top: 8px"
                class="label"
              >验证码：</div>
              <div class="code-content">
                <el-input
                  v-model="yzmForm.code"
                  style="width: 78%"
                  maxlength="30"
                  placeholder="请输验证码"
                />
                <div
                  class="code-btn"
                  :class="{'active-code-btn': flag }"
                  @click="getCode"
                >{{ !flag ? '获取验证码' : `${countDown}s后重新获取` }}</div>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </template>
      <div class="form-item">
        <button
          class="btn-primary"
          @click="login"
        >登录</button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import User from '@/api/user'
import Organization from '@/api/organization'
export default {
  name: 'LoginIndex',
  data() {
    return {
      isSoftWork: false, // 是否软著
      countDown: 60,
      flag: false,
      timer: null,
      activeIndex: '1',
      yzmRules: {
        phone: [
          {
            validator: (rule, value, callback) => {
              const regex = /^1[3-9]\d{9}$/
              if (regex.test(value)) {
                return callback()
              } else {
                return callback(new Error('请输入正确手机号'))
              }
            },
            trigger: 'blur'
          }
        ],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
      },
      yzmForm: {
        phone: '',
        code: ''
      },
      dialogVisible: true,
      username: '',
      password: '',
      options: [{
        value: '1',
        label: '天堰科技股份有限公司'
      }, {
        value: '2',
        label: '天津中医药大学'
      }, {
        value: '3',
        label: '天津医科大学'
      }, {
        value: '4',
        label: '南京医科大学'
      }],
      value: '1'
    }
  },
  mounted() {
    const clipboardData = navigator.clipboard.readText()
    console.log('/////', clipboardData)
  },
  methods: {
    /**
     * 导航菜单切换
     */
    handleTagSelect(key) {
      this.activeIndex = key
    },
    /**
     * 获取验证码
     */
    async getCode() {
      delete this.yzmRules.code
      this.$refs['yzmLoginForm'].validate(async(valid) => {
        if (valid) {
          this.flag = true
          if (this.timer !== null) return
          this.timer = setInterval(() => {
            if (this.countDown === 1) {
              clearInterval(this.timer)
              this.countDown = 60
              this.flag = false
              this.timer = null
            } else {
              this.countDown--
            }
          }, 1000)
          const res = await User.sendCode({
            phone: this.yzmForm.phone
          })
          if (!res.ok) {
            this.$message.error(res.message)
          }
        }
      })
    },
    async login() {
      if (this.activeIndex === '1') { // 密码登录
        if (this.username !== '' && this.password !== '') {
          const res = await User.login({
            username: this.username,
            password: this.password
          })
          if (res.ok) {
            sessionStorage.setItem('userInfo', JSON.stringify(res.data))
            this.$store.commit('CLOSE_LOGIN')
            this.$store.commit('SET_USERINFO', res.data)
            const orgRes = await Organization.queryOrgInfoByOrgId({ orgId: res.data.orgId })
            this.$store.commit('SET_ORG_INFO', orgRes.data)
            window.location.reload()
          } else {
            this.$message.error(res.message)
          }
        }
      } else { // 验证码登录
        this.yzmRules.code = [{ required: true, message: '请输入验证码', trigger: 'blur' }]
        this.$refs['yzmLoginForm'].validate(async(valid) => {
          if (valid) {
            const res = await User.loginPhone({
              phone: this.yzmForm.phone,
              code: this.yzmForm.code
            })
            if (res.ok) {
              sessionStorage.setItem('userInfo', JSON.stringify(res.data))
              this.$store.commit('CLOSE_LOGIN')
              this.$store.commit('SET_USERINFO', res.data)
              const orgRes = await Organization.queryOrgInfoByOrgId({ orgId: res.data.orgId })
              this.$store.commit('SET_ORG_INFO', orgRes.data)
              window.location.reload()
            } else {
              this.$message.error(res.message)
            }
          }
        })
      }
    },
    closeLoginPage() {
      this.$store.commit('CLOSE_LOGIN')
    }
  }
}
</script>

<style scoped lang="scss">
.login-dialog{
  ::v-deep .el-dialog{
    border-radius: 8px;
  }
  ::v-deep .el-icon-close{
    font-size: 32px;
    color: #000000;
  }
}

.login-form{
  padding: 0 40px 40px;
  .title{
    font-family: PingFangSC-Medium;
    font-size: 38px;
    color: #000000;
    line-height: 46px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 20px;
  }
  .form-item{
    margin-top: 30px;
    .label{
      font-family: PingFangSC-Regular;
      font-size: 16px;
      color: #000000;
      line-height: 20px;
      font-weight: 400;
      margin-bottom: 10px;
    }
    .code-content{
      display: flex;
      justify-content: space-between;
      .code-btn{
        width: 135px;
        background: #F1F5FB;
        border-radius: 4px;
        cursor: pointer;
        text-align: center;
        line-height: 45px;
        font-size: 16px;
        color: #0067FF;
        margin-left: 10px;
      }
      .active-code-btn{
        color: #9B9DA2;
      }
    }
    .el-input,.el-select{
      width: 100%;
      background: #FFFFFF;
      border-radius: 4px;
      ::v-deep input{
        height: 46px;
        font-family: PingFangSC-Regular;
        font-size: 16px;
        color: #7A7A7A;
        line-height: 20px;
        font-weight: 400;
        border-color: #595F68;
        &:active, &:focus{
          border-color: #0067FF;
        }
      }
    }
    .btn-primary{
      margin-top: 10px;
      width: 100%;
      height: 46px;
      font-family: PingFangSC-Regular;
      font-size: 18px;
      color: #FFFFFF;
      letter-spacing: 0;
      line-height: 22px;
      font-weight: 400;
    }
  }
}
::v-deep .el-menu.el-menu--horizontal{
  display: flex;
  justify-content: space-around;
  .el-menu-item{
    font-size: 20px;
    padding: 0;
  }
}
::v-deep .el-menu--horizontal > .el-menu-item.is-active{
  color: #0067FF;
}
</style>
