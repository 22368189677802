import Vue from 'vue'

// 自定义事件指令，点击需要判断登录的指令
Vue.directive('clickWithAuthLogin', {
  bind: function(el, binding, vnode) {
    el.onclick = function(event) {
      // 阻止事件冒泡
      event.stopPropagation()
      // 监听点击事件，判断是否登录
      if (!vnode.context.$store.state.isLogin) {
        // 未登录，跳转登录，附带当前页面的path,登录成功再跳转到本页面
        console.log('111')
      } else {
        if (binding.expression) {
          // 已登录则，如果有后续执行的函数，则继续做组件自己的事件
          binding.value()
        }
      }
    }
  }
})

Vue.directive('dragscroll', function(el) {
  el.onmousedown = function(ev) {
    const disX = ev.clientX
    const disY = ev.clientY
    const originalScrollLeft = el.scrollLeft
    const originalScrollTop = el.scrollTop
    const originalScrollBehavior = el.style['scroll-behavior']
    const originalPointerEvents = el.style['pointer-events']
    // auto: 默认值，表示滚动框立即滚动到指定位置。
    el.style['scroll-behavior'] = 'auto'
    // el.style['cursor'] = 'grabbing'
    // 鼠标移动事件是监听的整个document，这样可以使鼠标能够在元素外部移动的时候也能实现拖动
    document.onmousemove = function(ev) {
      ev.preventDefault()
      // 计算拖拽的偏移距离
      const distanceX = ev.clientX - disX
      const distanceY = ev.clientY - disY

      el.scrollTo(originalScrollLeft - distanceX, originalScrollTop - distanceY)
      // 在鼠标拖动的时候将点击事件屏蔽掉
      // el.style['pointer-events'] = 'none'
      // document.body.style['cursor'] = 'grabbing'
    }
    document.onmouseup = function() {
      document.onmousemove = null
      document.onmouseup = null
      el.style['scroll-behavior'] = originalScrollBehavior
      el.style['pointer-events'] = originalPointerEvents
      // el.style['cursor'] = 'grab'
    }
  }
})
