export const SOCKET_INIT = 'SOCKET_INIT' // 创建websocket
export const SOCKET_SEND = 'SOCKET_SEND' // 发送websocket 消息
export const SOCKET_CLOSE = 'SOCKET_CLOSE' // 关闭发送websocket
export const OPEN_LOGIN = 'OPEN_LOGIN' // 打开登录窗口
export const CLOSE_LOGIN = 'CLOSE_LOGIN' // 关闭登录窗口
export const SET_USERINFO = 'SET_USERINFO' // 设置用户信息
export const LOGOUT = 'LOGOUT' // 退出登录
export const SET_COURSE_ID = 'SET_COURSE_ID' // 设置课程id
export const SET_ORG_INFO = 'SET_ORG_INFO' // 设置组织信息
export const OPEN_AI = 'OPEN_AI' // 打开登录窗口
export const CLOSE_AI = 'CLOSE_AI' // 关闭登录窗口
export const SET_DOCTOR_PATIENT_LIST = 'SET_DOCTOR_PATIENT_LIST' // 设置医患沟通记录
export const SET_RESET_DOCTOR_PATIENT_LIST = 'SET_RESET_DOCTOR_PATIENT_LIST' // 重置医患沟通记录
