<template>
  <div class="all-course-container">
    <el-dialog
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :visible.sync="visible"
      top="8vh"
      width="1200px"
      title="全部课程"
      custom-class="profile-dialog"
      @close="cancel"
    >
      <div
        slot="default"
        class="dialog-content"
      >
        <div
          class="filter-item"
          style="padding-top: 15px;"
        >
          <div class="filter-title">专业大类:</div>
          <div class="filter-option">
            <span
              v-for="(item,index) in categoryList"
              :key="item"
              class="option-name"
              :class="{'active-option':index===activeCategory}"
              @click="onClickFilter('zy',index)"
            >{{ item }}</span>
          </div>
        </div>
        <div
          class="filter-item"
          style="padding-bottom: 15px; border-bottom: 1px solid #EAEAEA"
        >
          <div class="filter-title">发布年份:</div>
          <div class="filter-option">
            <span
              v-for="(item,index) in yearsList"
              :key="item"
              class="option-name"
              :class="{'active-option':index===activeYear}"
              @click="onClickFilter('nf',index)"
            >{{ item }}</span>
          </div>
        </div>
        <div class="card-list">
          <el-row :gutter="20">
            <el-col
              v-for="item in courseList"
              :key="item.courseId"
              :span="8"
              style="margin: 5px 0"
            >
              <div
                class="card-item"
              >
                <div class="header">
                  <div
                    v-if="item.show"
                    class="is-enter"
                  >
                    <el-checkbox
                      v-model="item.checked"
                      label="不限"
                    >{{ item.checked?'已加入':'未加入' }}</el-checkbox>
                  </div>
                  <div class="eye-container">
                    <template v-if="item.numTop">
                      <img
                        class="top-tag"
                        :src="require(`@/assets/home/${item.numTop}.png`)"
                      >
                    </template>

                    <div style="display: flex;min-width: 45px;justify-content: center">
                      <img
                        class="eye-img"
                        src="@/assets/home/眼睛.png"
                      >
                      <span style="white-space: nowrap">{{ item.browseNum || 0 }}</span>
                    </div>
                  </div>
                </div>

                <img
                  v-if="item.imgPath == null || item.imgPath === ''"
                  class="book-img"
                  src="@/assets/home/样例书1.png"
                  alt=""
                >
                <img
                  v-else
                  class="book-img"
                  :src="resourceUrl + item.imgPath"
                  alt=""
                >
                <div class="right">
                  <div class="title">{{ item.courseName }}</div>
                  <div class="name-orgz">
                    <div class="name">{{ item.directorName }}</div>
                    <div class="orgz">{{ item.directorSchool }}</div>
                  </div>
                </div>
                <div
                  v-if="!item.show"
                  class="lock"
                >
                  <i class="el-icon-lock" />
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="footer-container">
          <el-button @click="cancel">取消</el-button>
          <el-button
            type="primary"
            @click="enter"
          >确定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Course from '@/api/course'
import config from '@/config'
export default {
  name: 'AllCourse',
  props: {
    allCourse: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      courseList: [],
      resourceUrl: config.VUE_APP_RESOURCE_URL,
      visible: true,
      activeCategory: 0,
      activeYear: 0,
      categoryList: [
        '全部',
        '护理学类',
        '临床医学类'
      ],
      yearsList: [
        '全部',
        '2016',
        '2017',
        '2018',
        '2019',
        '2020',
        '2021',
        '2022',
        '2023',
        '2024'
      ],
      courseTop: []
    }
  },
  watch: {
    allCourse: {
      handler(val) {
        Course.queryCourseBrowseNumTop().then(res => {
          this.courseTop = res.data
          this.allCourse.forEach(item => {
            item.numTop = null
            this.courseTop.forEach((topItem, topIdx) => {
              if (topItem.courseId === item.courseId) {
                item.numTop = topIdx + 1
              }
            })
          })
          this.courseList = JSON.parse(JSON.stringify(this.allCourse))
        })
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    onClickFilter(type, index) {
      type === 'zy' ? this.activeCategory = index : this.activeYear = index
      const zydl = this.categoryList[this.activeCategory] // 专业大类
      const year = this.yearsList[this.activeYear] // 年份
      if (zydl === '全部' && year === '全部') {
        this.courseList = this.allCourse
      } else if (zydl === '全部' && year !== '全部') {
        this.courseList = this.allCourse.filter(item2 => item2.createTime.includes(year))
      } else if (zydl !== '全部' && year === '全部') {
        this.courseList = this.allCourse.filter(item2 => item2.courseType === zydl)
      } else {
        this.courseList = this.allCourse.filter(item2 => item2.courseType === zydl && item2.createTime.includes(year))
      }
    },
    openDialog() {
      this.visible = true
    },
    enter() {
      if (!this.$store.getters.isLogin) {
        this.$store.commit('OPEN_LOGIN')
        return
      }
      // 获取当前选中
      const ids = []
      const addIds = []
      this.courseList.forEach(item => {
        if (item.checked && item.show) {
          ids.push(item.courseId)
        }
        if (!item.checked) {
          addIds.push(item.courseId)
        }
      })
      if (ids.length === 0) {
        this.$message.error('请至少添加1门课程')
        return
      }
      this.$emit('enter', addIds)
    },
    cancel() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="scss">
.all-course-container{
  ///deep/ .el-checkbox__input.is-checked + .el-checkbox__label{
  //  color: #0067FF  ;
  //}
  /deep/ .el-checkbox__inner{
    width: 14px;
    height: 14px;
    margin-bottom: 2px;
    &:after{
      height: 7px;
      width: 3px;
      border-width: 2px;
    }
  }
  /deep/ .el-checkbox__label{
    padding-left: 6px;
    font-size: 14px;
    color: #0067FF !important;
  }
  /deep/ .el-dialog__body{
    padding: 0;
  }
  .dialog-content{
    height: 75vh;
    .filter-item{
      display: flex;
      padding: 0 30px;
      .filter-title{
        font-size: 16px;
        color: #000000;
        font-weight: 500;
        line-height: 30px;
        margin-right: 24px;
      }
      .filter-option{
        width: calc(100% - 100px);
        .option-name{
          border-radius: 16px;
          display: inline-block;
          min-width: 64px;
          padding: 0 16px;
          margin-bottom: 10px;
          height: 32px;
          text-align: center;
          line-height: 32px;
          margin-right: 12px;
          cursor: pointer;
          &:hover{
            background: #0067FF;
            color: #fff;
          }
        }
        .active-option{
          background: #0067FF;
          color: #fff;
        }
      }
    }
    .card-list{
      height: calc(100% - 232px);
      overflow-y: auto;
      margin-top: 8px;
      padding: 0 34px;
      padding-bottom: 30px;
      .card-item{
        position: relative;
        display: flex;
        width: 100%;
        height: 150px;
        background: #F1F5FB;
        border-radius: 4px;
        padding: 9px 16px;
        &:nth-child(4n){
          margin-right: 0;
        }
        .header{
          display: flex;
          position: absolute;
          width: 100%;
          justify-content: space-between;
          align-items: flex-start;
          left:6px;
          padding-right: 12px;
          .is-enter{
            width: 92px;
            height: 28px;
            background: #fff;
            left: 4px;
            top: 4px;
            border-radius: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .eye-container{
            position: absolute;
            right: 10px;
            top: 0;
            font-size: 12px;
            color: #FFFFFF;
            border-radius: 11px;
            padding-right: 10px;
            //padding: 10px 20px;
            height: 22px;
            background: rgba(0,0,0,0.5);
            display: flex;
            align-items: center;
            justify-content: space-between;
            .eye-img{
              width: 12px;
              margin-right: 3px;
            }
            .top-tag{
              width: 32px;
              height: 22px;
              border-radius: 11px;
              //position: absolute;
              //left: 0;
              //top: 0;
            }
          }
        }

        .book-img{
          width: 100px;
          height: 100%;
          margin-right: 22px;
        }
        .right{
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .title{
            font-size: 22px;
            color: #000000;
          }
          .name-orgz{
            font-size: 16px;
            color: #7A7A7A;
            .name{
              margin-bottom: 6px;
            }
          }
        }
        .lock{
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          opacity: .3;
          border-radius: 4px;
          background: #000;
          content: "el-icon-lock";
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          i{
            font-size: 22px;
          }
        }
      }
    }
    .footer-container{
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0 30px;
    }
  }
}
</style>
