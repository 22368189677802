import * as TYPES from './mutation-types'
const actions = {
  /**
   * 创建websocket连接
   */
  socketInit({ commit }, data) {
    commit(TYPES.SOCKET_INIT, data)
  },
  /**
   * 关闭websocket连接
   */
  socketClose({ commit }) {
    commit(TYPES.SOCKET_CLOSE)
  },
  /**
   * socket发送数据
   */
  socketSend({ commit }, data) {
    commit(TYPES.SOCKET_SEND, data)
  }
}
export default actions
