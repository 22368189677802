import { render, staticRenderFns } from "./AllCourse.vue?vue&type=template&id=83e044fe&scoped=true"
import script from "./AllCourse.vue?vue&type=script&lang=js"
export * from "./AllCourse.vue?vue&type=script&lang=js"
import style0 from "./AllCourse.vue?vue&type=style&index=0&id=83e044fe&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "83e044fe",
  null
  
)

export default component.exports