import { get, post, postFrom } from '@/utils/request'

class Task {
  /**
   * 添加任务
   */
  async addTask(data) {
    const res = await postFrom('/task/addTask', data)
    return res
  }
  /**
   * 编辑任务
   */
  async editTask(data) {
    const res = await postFrom('/task/editTask', data)
    return res
  }
  /**
   * 删除任务
   */
  async removeTaskById(data) {
    const res = await postFrom('/task/removeTaskById', data)
    return res
  }
  /**
   * 添加学习资源
   */
  async addTaskStudyResource(orgId, data) {
    const res = await post(`/task/addTaskStudyResource/${orgId}`, data)
    return res
  }
  /**
   * 查询生理学习、理论知识、用药学习
   */
  async queryTaskArticle(data) {
    const res = await get('/task/queryTaskArticle', data)
    return res
  }
  /**
   * 根据课程id查询二级、三级任务
   */
  async queryTaskByCourseId(data) {
    const res = await get('/task/queryTaskByCourseId', data)
    return res
  }

  /**
   * 查询习题最大数量
   */
  async queryTaskExercisesMaxNum(data) {
    const res = await get('/task/queryTaskExercisesMaxNum', data)
    return res
  }

  /**
   * 习题随机抽题
   */
  async queryTaskExercisesRandom(data) {
    const res = await get('/task/queryTaskExercisesRandom', data)
    return res
  }
  /**
   * 习题随机抽题
   */
  async queryTaskOperationalTechniques(data) {
    const res = await get('/task/queryTaskOperationalTechniques', data)
    return res
  }
  /**
   * 查询产品推介
   */
  async queryTaskProductIntroduction(data) {
    const res = await get('/task/queryTaskProductIntroduction', data)
    return res
  }
  /**
   * 查询学习资源
   */
  async queryTaskStudyResource(data) {
    const res = await get('/task/queryTaskStudyResource', data)
    return res
  }
  /**
   * 查询虚拟结合训练-基础
   */
  async queryTaskVirtualTrainingBase(data) {
    const res = await get('/task/queryTaskVirtualTrainingBase', data)
    return res
  }
  /**
   * 查询虚拟结合训练-病例
   */
  async queryTaskVirtualTrainingCase(data) {
    const res = await get('/task/queryTaskVirtualTrainingCase', data)
    return res
  }
  async removeVrDataById(data) {
    const res = await postFrom('/import/removeVrDataById', data)
    return res
  }

  /**
   * 查询理论学习试题
   */
  async queryTheoreticalStudyExercises(data) {
    const res = await get('/task/queryTheoreticalStudyExercises', data)
    return res
  }
  /**
   * 任务-收藏取消收藏
   */
  async taskCollectAndCancel(data) {
    const res = await get('/taskCollectUser/taskCollectAndCancel', data)
    return res
  }
  /**
   * 开始学习
   */
  async startStudy(data) {
    const res = await postFrom('/studyRecords/startStudy', data)
    return res
  }
  /**
   * 结束学习
   */
  async endStudy(data) {
    const res = await post('/studyRecords/endStudy', data)
    return res
  }
  /**
   * 查询checkList
   */
  async queryTaskCheckList(data) {
    const res = await get('/checklist/queryTaskCheckList', data)
    return res
  }
  /**
   * 保存学习日志记录
   */
  async saveStudyRecordsEvaluate(data) {
    const res = await post('/studyRecordsEvaluate/saveStudyRecordsEvaluate', data)
    return res
  }
  /**
   * 查询形成性评价详情
   */
  async queryFormativeEvaluationDetails(data) {
    const res = await get('/studyRecords/queryFormativeEvaluationDetails', data)
    return res
  }
  /**
   * 查询形成性评价
   */
  async queryFormativeEvaluationPage(data) {
    const res = await get('/studyRecords/queryFormativeEvaluationPage', data)
    return res
  }
  /**
   * 查询学习记录详情
   */
  async queryStudyRecordsDetails(data) {
    const res = await get('/studyRecords/queryStudyRecordsDetails', data)
    return res
  }
  /**
   * 查询学习记录列表
   */
  async queryStudyRecordsPage(data) {
    const res = await get('/studyRecords/queryStudyRecordsPage', data)
    return res
  }
  /**
   * 上传评价视频
   */
  async upLoadEvaluateVideo(data) {
    const res = await postFrom('/studyRecordsEvaluate/upLoadEvaluateVideo', data)
    return res
  }
  /**
   * 查询课程下所有任务（三级）
   */
  async queryTaskAllListByCourse(data) {
    const res = await get('/task/queryTaskAllListByCourse', data)
    return res
  }
  /**
   * 统计-成长曲线-习题
   */
  async queryStatisticsScoreExercises(data) {
    const res = await get('/studyRecords/queryStatisticsScoreExercises', data)
    return res
  }
  /**
   * 统计 -成长曲线-虚拟
   */
  async queryStatisticsScoreVrBase(data) {
    const res = await get('/studyRecords/queryStatisticsScoreVrBase', data)
    return res
  }
  /**
   * 统计 -学习记录
   */
  async queryStatisticsStudyRecords(data) {
    const res = await get('/studyRecords/queryStatisticsStudyRecords', data)
    return res
  }
  /**
   * 教师查询习题
   */
  async queryTaskExercisesList(data) {
    const res = await get('/task/queryTaskExercisesList', data)
    return res
  }
  /**
   * 统计-训练-筛选下拉查询
   */
  async queryStatisticsTrainSelect(data) {
    const res = await get('/studyRecords/queryStatisticsTrainSelect', data)
    return res
  }
  /**
   * 统计-训练-所有成绩
   */
  async queryStatisticsTrainAll(data) {
    const res = await post('/studyRecords/queryStatisticsTrainAll', data)
    return res
  }
  /**
   * 统计-训练-首次成绩
   */
  async queryStatisticsTrainFirst(data) {
    const res = await post('/studyRecords/queryStatisticsTrainFirst', data)
    return res
  }
  // /**
  //  * 统计-训练-Max和Avg
  //  */
  // async queryStatisticsTrainMaxAndAvg(data) {
  //   const res = await post('/studyRecords/queryStatisticsTrainMaxAndAvg', data)
  //   return res
  // }
  /**
   * 统计 -训练-最大值数据
   */
  async queryStatisticsTrainMax(data) {
    const res = await post('/studyRecords/queryStatisticsTrainMax', data)
    return res
  }
  /**
   * 统计 -训练-平均值数据
   */
  async queryStatisticsTrainAvg(data) {
    const res = await post('/studyRecords/queryStatisticsTrainAvg', data)
    return res
  }
  /**
   * 统计-虚拟知识点统计
   */
  async virtualKnowledgePoints(data) {
    const res = await post('/virtualKnowledgePoints/queryVirtualKnowledgePointsTree', data)
    return res
  }
  /**
   * 统计-实体知识点统计
   */
  async queryEnterKnowledgePointsTree(data) {
    const res = await post('/virtualKnowledgePoints/queryEnterKnowledgePointsTree', data)
    return res
  }
  /**
   * 统计 -资源统计-课程 -不分页
   */
  async queryStatisticsResourceCourse(data) {
    const res = await post('/studyRecords/queryStatisticsResourceCourse', data)
    return res
  }
  /**
   * 统计 -资源统计-虚拟技能实践 -分页
   */
  async queryStatisticsVrBase(data) {
    const res = await post('/studyRecords/queryStatisticsVrBase', data)
    return res
  }
  /**
   * 统计 -资源统计-活跃用户 -分页
   */
  async queryStatisticsActiveUser(data) {
    const res = await postFrom('/studyRecords/queryStatisticsActiveUser', data)
    return res
  }
  /**
   * 保存虚拟知识点记录
   */
  async saveVirtualKnowledgePointsRecord(data) {
    const res = await post('/virtualKnowledgePointsRecord/saveVirtualKnowledgePointsRecord', data)
    return res
  }

  /**
   * 查询练习次数
   */
  async queryTrainNumByUserIdAndCourseId(data) {
    const res = await get('/studyRecords/queryTrainNumByUserIdAndCourseId', data)
    return res
  }
  /**
   * 查询课程下理论学习已学个数
   */
  async queryTheoryModuleNumberUserIdAndCourseId(data) {
    const res = await get('/studyRecords/queryTheoryModuleNumberUserIdAndCourseId', data)
    return res
  }
  /**
   * 教师评价
   */
  async teacherFormativeEvaluation(data) {
    const res = await post('/studyRecords/teacherFormativeEvaluation', data)
    return res
  }
  /**
   * 查询影像学习
   */
  async queryTaskDicomList(data) {
    const res = await get('/taskDicom/queryTaskDicomList', data)
    return res
  }
  /**
   * 查询任务
   */
  async queryTaskById(data) {
    const res = await get('/task/queryTaskById', data)
    return res
  }
  /**
   * 机构导入自定义试题
   */
  async importOrgExercise(data) {
    const res = await get('/import/importOrgExercise', data)
    return res
  }
  /**
   * 导出笔记
   */
  async exportNoteList(data) {
    const res = await get('/export/exportNoteList', data)
    return res
  }
  /**
   * 导出答疑
   */
  async exportNoteAnswerQuestion(data) {
    const res = await get('/export/exportNoteAnswerQuestion', data)
    return res
  }
  /**
   * 保存虚拟浏览量
   */
  async saveVirtualBrowse(data) {
    const res = await get('/taskVirtualTrainingBaseBrowse/saveVirtualBrowse', data)
    return res
  }
  /**
   * 删除学习资源-组织机构
   */
  async removeTaskStudyResourceOrg(data) {
    const res = await postFrom('/task/removeTaskStudyResourceOrg', data)
    return res
  }
  /**
   * 删除学习资源-组织机构
   */
  async removeTaskExercisesOrgBatch(data) {
    const res = await postFrom('/task/removeTaskExercisesOrgBatch', data)
    return res
  }
  /**
   * 添加学习资源-链接-组织机构
   */
  async addTaskStudyResourceLinkOrg(data) {
    const res = await postFrom('/task/addTaskStudyResourceLinkOrg', data)
    return res
  }
  /**
   * 编辑学习资源-链接-组织机构
   */
  async editTaskStudyResourceLinkOrg(data) {
    const res = await postFrom('/task/editTaskStudyResourceLinkOrg', data)
    return res
  }

  async getSystemLLMURL() {
    const res = await get('/ai/getSystemLLMURL')
    return res
  }
}

export default new Task()
