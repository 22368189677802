import Vue from 'vue'
import axios from 'axios'
import config from '@/config'
const service = axios.create({
  baseURL: config.VUE_APP_BASE_API, // url = base url + request url
  timeout: 50000 // request timeout
})

const noTokenUrl = ['/user/login'] // 不需要token的请求

service.interceptors.request.use(
  config => {
    if (!noTokenUrl.includes(config.url)) {
      // 从 sessionStorage 中获取 Token
      const user = sessionStorage.getItem('userInfo')

      // 如果 Token 存在，则将 Token 添加到请求头中
      if (user) {
        const userObj = JSON.parse(user)
        config.headers.token = userObj.token
      }
    }

    return config
  },
  error => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.respCode === 'M0000' || res.respCode === 'M0001' || res.respCode === 'M0002' || res.respCode === 'M0003') {
      Vue.prototype.$message.error(res.message)
      return Promise.reject(new Error(res.message || 'Error'))
    }
    return res
  },
  error => {
    if (!error.response) {
      Vue.prototype.$message.error('请检查 API 是否异常')
    } else {
      // 判断请求超时
      if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
        Vue.prototype.$message.error('请求超时')
      }
    }
    console.log('err' + error) // for debug
    return Promise.reject(error)
  }
)

// 导出常用函数

/**
 * @param {string} url
 * @param {object} data
 * @param {object} params
 */
export function post(url, data = {}, params = {}) {
  return service({
    method: 'post',
    url,
    data,
    params
  })
}

/**
 * @param {string} url
 * @param {object} params
 */
export function get(url, params = {}) {
  return service({
    method: 'get',
    url,
    params
  })
}

/**
 * @param {string} url
 * @param {object} data
 * @param {object} params
 */
export function put(url, data = {}, params = {}) {
  return service({
    method: 'put',
    url,
    params,
    data
  })
}

/**
 * @param {string} url
 * @param {object} params
 */
export function _delete(url, params = {}) {
  return service({
    method: 'delete',
    url,
    params
  })
}
export function postFrom(url, data = {}, params = {}) {
  const formData = new FormData()
  Object.keys(data).forEach(key => {
    if (data[key] instanceof Array) {
      data[key].forEach(item => {
        formData.append(key, item)
      })
    } else {
      formData.append(key, data[key])
    }
  })
  data = formData
  return service({
    method: 'post',
    url,
    data,
    params
  })
}

export function postDownload(url, data = {}, params = {}) {
  return service({
    method: 'post',
    url,
    data,
    params,
    responseType: 'blob'
  })
}
