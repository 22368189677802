const state = {
  isLoginModel: false, // 是否打开登录对话框
  isAiModel: false, // 是否打开Ai对话框
  isLogin: !!sessionStorage.getItem('userInfo'), // 是否登录
  userInfo: sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : {}, // 用户信息 role角色;1：教师；2：学生；3：天堰管理员
  courseId: sessionStorage.getItem('courseId') || '', // 课程ID
  orgInfo: sessionStorage.getItem('orgInfo') ? JSON.parse(sessionStorage.getItem('orgInfo')) : {}, // 机构信息
  doctorPatientList: [] // 医患沟通记录
}
export default state
