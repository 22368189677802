import { get, post, postFrom } from '@/utils/request'

class Organization {
  /**
   * 新增机构和课程权限
   */
  async addOrg(data) {
    const res = await post('/organization/addOrg', data)
    return res
  }
  /**
   * 编辑机构和课程权限
   */
  async editOrgById(data) {
    const res = await post('/organization/editOrgById', data)
    return res
  }
  /**
   * 修改机构信息
   */
  async editOrgInfoByOrgId(data) {
    const res = await post('/organization/editOrgInfoByOrgId', data)
    return res
  }
  /**
   * 查询机构详情课程权限
   */
  async queryOrgDetails(data) {
    const res = await get('/organization/queryOrgDetails', data)
    return res
  }
  /**
   * 查询机构管理员用户名密码
   */
  async queryOrgAdminUserInfo(data) {
    const res = await get('/organization/queryOrgAdminUserInfo', data)
    return res
  }
  /**
   * 查询机构信息
   */
  async queryOrgInfoByOrgId(data) {
    const res = await get('/organization/queryOrgInfoByOrgId', data)
    return res
  }
  /**
   * 天堰管理员查询所有机构
   */
  async queryOrgList(data) {
    const res = await get('/organization/queryOrgList', data)
    return res
  }
  /**
   * 删除机构
   */
  async removeOrgById(data) {
    const res = await postFrom('/organization/removeOrgById', data)
    return res
  }

  /**
   * 上传机构logo  已在组件内直接对接
   */
  async upLoadLogo(data) {
    const res = await get('/organization/upLoadLogo', data)
    return res
  }
}

export default new Organization()
