const getters = {
  isLogin: state => state.isLogin,
  isLoginModel: state => state.isLoginModel,
  userInfo: state => state.userInfo,
  courseId: state => state.courseId,
  orgInfo: state => state.orgInfo,
  isAiModel: state => state.isAiModel,
  doctorPatientList: state => state.doctorPatientList
}
export default getters
