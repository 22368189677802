<template>
  <div class="ty-confirm-container">
    <transition name="el-fade-in-linear">
      <div
        v-if="isShow"
        class="ty-confirm"
      />
    </transition>
    <div
      v-if="isShow"
      class="ty-confirm-box"
    >
      <div class="ty-confirm-header">
        <div>{{ text.type }}</div>
        <img
          src="@/assets/comm/关闭.png"
          @click="isShow = false"
        >
      </div>
      <div class="ty-confirm-msg">
        <div v-html="text.msg" />
      </div>
      <div class="ty-confirm-footer">
        <button
          v-if="text.btn.no"
          class="btn-info"
          style="margin-right: 17px"
          @click="close()"
        >{{ text.btn.no }}</button>
        <button
          v-if="text.btn.ok"
          class="btn-primary"
          style="margin-right: 17px"
          @click="ok()"
        >{{ text.btn.ok }}</button>
        <button
          v-if="text.btn.del"
          class="btn-error"
          @click="ok()"
        >{{ text.btn.del }}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Confirm',
  data() {
    return {
      isShow: true,
      text: {
        type: '提示',
        msg: '确定删除此条信息？',
        btn: {
          ok: '确定',
          no: '取消'
        }
      }
    }
  },
  methods: {
    close() {
      console.log('关闭')
    },
    ok() {
      console.log('确定')
    },
    closeDia() {
      this.isShow = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .ty-confirm{
    opacity: .6;
    background: #000;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    z-index: 2999;
  }
  .ty-confirm-box{
    background: #fff;
    z-index: 3000;
    width: 540px;
    height: 350px;
    border-radius: 4px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    .ty-confirm-header{
      font-size: 20px;
      color: #000000;
      font-weight: 600;
      height: 68px;
      border-bottom: 1px solid #EBEBEB;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 34px;
      img{
        cursor: pointer;
        width: 26px;
      }
    }
    .ty-confirm-msg{
      color: #000;
      font-size: 20px;
      text-align: center;
      text-overflow: ellipsis;
      overflow: hidden;
      padding: 88px 32px;
    }
    .ty-confirm-footer{
      width: 100%;
      display: flex;
      justify-content: right;
      padding-right: 34px;
    }
  }
</style>
