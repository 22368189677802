import { get, post, postFrom } from '@/utils/request'

class Course {
  /**
   * 新增课程
   */
  async addCourse(data) {
    const res = await post('/course/addCourse', data)
    return res
  }
  /**
   * 编辑课程
   */
  async editCourse(data) {
    const res = await post('/course/editCourse', data)
    return res
  }
  /**
   * 课程添加封面图片
   */
  async editCourseImg(data) {
    const res = await postFrom('/course/editCourseImg', data)
    return res
  }
  /**
   * 修改课程状态（发布、下架）
   */
  async editCourseStatus(data) {
    const res = await postFrom('/course/editCourseStatus', data)
    return res
  }
  /**
   * 查询全部课程（未发布+已发布）
   */
  async queryCourseAllList(data) {
    const res = await get('/course/queryCourseAllList', data)
    return res
  }
  /**
   * 查询课程详情
   */
  async queryCourseDetailsByCourseId(data) {
    const res = await get('/course/queryCourseDetailsByCourseId', data)
    return res
  }
  /**
   * 查询全部课程（已发布）
   */
  async queryCourseList(data) {
    const res = await get('/course/queryCourseList', data)
    return res
  }

  /**
   * 查询机构权限下课程
   */
  async queryOrgCourseList(data) {
    const res = await get('/course/queryOrgCourseList', data)
    return res
  }
  /**
   * 查询机构权限下课程详情列表
   */
  async queryOrgCourseInfoList(data) {
    const res = await get('/course/queryOrgCourseInfoList', data)
    return res
  }

  /**
   * 查询用户课程信息学习进度
   */
  async queryCourseStudyProgressByCourseId(data) {
    const res = await get('/course/queryCourseStudyProgressByCourseId', data)
    return res
  }
  /**
   * 查询我的书架
   */
  async queryMyBookshelf(data) {
    const res = await get('/course/queryMyBookshelf', data)
    return res
  }

  /**
   * 加入书架
   */
  async addBookshelf(data) {
    const res = await get('/bookshelf/addBookshelf', data)
    return res
  }
  /**
   * 删除书架
   */
  async removeBookshelf(data) {
    const res = await get('/bookshelf/removeBookshelf', data)
    return res
  }

  /**
   * 批量加入书架
   */
  async addBookshelfBatch(data) {
    const res = await postFrom('/bookshelf/addBookshelfBatch', data)
    return res
  }

  /**
   * 删除课程
   */
  async removeCourseById(data) {
    const res = await get('/course/removeCourseById', data)
    return res
  }

  /**
   * 首页查询课程详情
   */
  async queryHomeCourseDetailsByCourseId(data) {
    const res = await get('/course/queryHomeCourseDetailsByCourseId', data)
    return res
  }

  /**
   * 查询天堰用户课程列表
   * @param data
   */
  async queryUserCourseList(data) {
    const res = await get('/course/queryUserCourseList', data)
    return res
  }
  /**
   * 查询课程浏览量top5
   * @param data
   */
  async queryCourseBrowseNumTop(data) {
    const res = await get('/course/queryCourseBrowseNumTop', data)
    return res
  }
  /**
   * 天堰管理员查询任务权限树
   * @param data
   */
  async queryOrgTaskTree(data) {
    const res = await get('/organizationTask/queryOrgTaskTree', data)
    return res
  }
  /**
   * 天堰管理员查询任务权限List
   * @param data
   */
  async queryOrgTaskList(data) {
    const res = await get('/organizationTask/queryOrgTaskList', data)
    return res
  }
}

export default new Course()
