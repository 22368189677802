import { get, post, postFrom } from '@/utils/request'

class User {
  /**
   * 新增天堰用户
   */
  async addTellyesUser(data) {
    const res = await postFrom('/user/addTellyesUser', data)
    return res
  }
  /**
   * 添加用户
   */
  async addUser(data) {
    const res = await post('/user/addUser', data)
    return res
  }
  /**
   * 编辑天堰用户
   */
  async editTellyesUser(data) {
    const res = await postFrom('/user/editTellyesUser', data)
    return res
  }
  /**
   * 编辑用户
   */
  async editUser(data) {
    const res = await post('/user/editUser', data)
    return res
  }
  /**
   * 修改密码
   */
  async editUserPassWord(data) {
    const res = await postFrom('/user/editUserPassWord', data)
    return res
  }
  /**
   * 用户登录
   */
  async login(data) {
    const res = await postFrom('/user/login', data)
    return res
  }
  /**
   * 查询天堰用户列表
   */
  async queryTellyesUserList(data) {
    const res = await get('/user/queryTellyesUserList', data)
    return res
  }
  /**
   * 查询用户列表
   */
  async queryUserList(data) {
    const res = await post('/user/queryUserList', data)
    return res
  }
  /**
   * 查询用户角色
   */
  async queryUserRoleByUserId(data) {
    const res = await get('/user/queryUserRoleByUserId', data)
    return res
  }
  /**
   * 删除用户
   */
  async removeUserByUserId(data) {
    const res = await postFrom('/user/removeUserByUserId', data)
    return res
  }
  /**
   * 通过机构id查询用户列表
   */
  async queryUserListByOrgId(data) {
    const res = await get('/user/queryUserListByOrgId', data)
    return res
  }
  /**
   * 通过机构id查询用户列表
   */
  async queryUserSelect() {
    const res = await get('/user/queryUserSelect')
    return res
  }
  /**
   * 发送验证码
   */
  async sendCode(data) {
    const res = await postFrom('/user/sendCode', data)
    return res
  }
  /**
   * 手机号登录
   */
  async loginPhone(data) {
    const res = await postFrom('/user/loginPhone', data)
    return res
  }

  /**
   * 退出登录
   */
  async logout() {
    const res = await postFrom('/user/logOut')
    return res
  }

  /**
   * Ai语音分析
   */
  async getAIEvaluate(data) {
    const res = await post('/ai/getAIEvaluate', data)
    return res
  }
}

export default new User()
