import * as TYPES from './mutation-types'
import WebsocketClass from '@/utils/webSocket'
import { socketMessage } from '@/store/socket/message'

const mutations = {
  [TYPES.SOCKET_INIT](state, url) {
    state.socket = new WebsocketClass(url, data => {
      socketMessage(state, data)
    })
    state.socket.connect()
  },
  [TYPES.SOCKET_CLOSE](state) {
    state.socket.close()
  },
  [TYPES.SOCKET_SEND](state, data) {
    state.socket && state.socket.send(data)
  },
  [TYPES.OPEN_LOGIN](state, data) {
    state.isLoginModel = true
  },
  [TYPES.CLOSE_LOGIN](state, data) {
    state.isLoginModel = false
  },
  [TYPES.OPEN_AI](state, data) {
    state.isAiModel = true
  },
  [TYPES.CLOSE_AI](state, data) {
    state.isAiModel = false
  },
  [TYPES.SET_USERINFO](state, data) {
    state.isLogin = true
    state.userInfo = data
    sessionStorage.setItem('userInfo', JSON.stringify(data))
  },
  [TYPES.LOGOUT](state) {
    state.isLogin = false
    state.userInfo = {}
    sessionStorage.removeItem('userInfo')
  },
  [TYPES.SET_COURSE_ID](state, data) {
    state.courseId = data
    sessionStorage.setItem('courseId', data)
  },
  [TYPES.SET_ORG_INFO](state, data) {
    state.orgInfo = data
    sessionStorage.setItem('orgInfo', JSON.stringify(data))
  },
  [TYPES.SET_DOCTOR_PATIENT_LIST](state, data) {
    state.doctorPatientList.push(data)
  },
  [TYPES.SET_RESET_DOCTOR_PATIENT_LIST](state, data) {
    state.doctorPatientList = []
  }
}
export default mutations
