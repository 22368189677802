
<template>
  <div class="main">
    <Sidebar @toggleChange="toggleChange" />
    <div
      class="content"
      :style="{width: isOpen? 'calc(100% - 220px)' : 'calc(100% - 120px)'}"
    >
      <router-view :key="key" />
    </div>
  </div>

</template>
<script>
import Sidebar from './components/SideBar.vue'
export default {
  name: 'LayoutIndex',
  components: {
    Sidebar
  },
  data() {
    return {
      isOpen: true
    }
  },
  computed: {
    key() {
      return this.$route.path + Math.random()
    }
  },
  methods: {
    toggleChange(val) {
      this.isOpen = val
    }
  }
}
</script>
<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  .content{
    width: calc(100% - 220px);
    height: 100%;
    transition: all .2s ease-in-out;
  }
}

</style>
